import React from 'react';
import Header from '../components/header/Header';
import Info from '../components/about/About';
import Details from '../components/details/Details';

const About = () => {
  return (
    <>
    <Header/>
    <Info/>
    <Details/>
    </>
  )
}

export default About