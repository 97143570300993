import React from 'react';
import './details.css';
import media from "../../assets/media.png"

const Details = () => {
  return (
    <div className='details section' id='details'>
      <h2 className='section__title'>Patents</h2>
      <span className='section__subtitle'>My patents</span>
      <div className='details__container container grid'>

        <ul className='list'>
          <li>P7446-US, Deep Neural Net with Attention for Multi-channel Multi-touch Attribution
          </li>
          <li>P6703-US, Determining Algorithmic Multi-Channel media attribution based on discrete time survival modeling</li>
          <li>P6140-US, Intraday Modeling to adjust online ad distribution
          </li>
          <li>P17/948914 Graphical incremental attribution model based on conditional intensity.
          </li>
          <li>P10260-US, Facilitating Analysis of Attribution Models</li>
        </ul>
      </div>

      <h2 className='section__title'>AI/ML conferences</h2>
      <span className='section__subtitle'>I have 50+ conducted peer reviews in following AI/ML conferences</span>
      <div className='details__container container grid'>
        <ul className='list'>
          <li>IEEE Silchar Subsection Conference</li>
          <li>International Conference on Advancements and Key Challenges in Green Energy and Computing</li>
          <li>Women in Machine Learning Workshop (WiML)</li>
          <li>IEEE Transactions on Neural Networks and Learning Systems</li>
          <li>IEEE International Conference on Artificial Intelligence, Computational Electronics and Communication Systems</li>
          <li>International Conference on Data Science and Management of Data</li>
          <li>IEEE Transactions on Image Processing</li>
          <li>IEEE Winter Conference on Applications of Computer Vision</li>
        </ul>
      </div>

      <h2 className='section__title'>Media</h2>
      <span className='section__subtitle'>Press media covering my work</span>
      <div className='details__container container grid'>
        <img src={media} className='media__img'></img>
        <ul class="media-list">
          <li><a href="https://www.msn.com/en-us/money/other/sai-kumar-arava-on-the-applications-of-machine-learning/ar-AA1sBndx
" target="_blank">Applications of machine learning - MSN</a></li>
          <li><a href="https://www.techtimes.com/articles/306952/20240730/sai-kumar-arava-ai-and-machine-learning-are-the-keys-to-enhancing-business-insights.htm" target="_blank">AI and Machine Learning Are the Keys to Enhancing Business Insights - Techtimes</a></li>
          <li><a href="https://techbullion.com/sai-kumar-arava-transforming-marketing-analytics-with-machine-learning/" target="_blank">Transforming Marketing Analytics with Machine Learning - Techbullion</a></li>
          <li><a href="https://goodmenproject.com/technology/how-sai-kumar-arava-drives-value-to-businesses-through-ai/" target="_blank">How Sai Kumar Arava Drives Value to Businesses Through AI</a></li>
          <li><a href="https://ceoweekly.com/sai-kumar-arava-and-the-evolution-of-ai-in-customer-service/" target="_blank">Sai Kumar Arava and the Evolution of AI in Customer Service - CEO weekly</a></li>
          <li><a href="https://finance.yahoo.com/news/iicspa-welcomes-fourteen-fellows-2024-121500123.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAAcFKa8SgVgl8h6AhyxSsNRPSLOAYqMPxRaw0yrWm3UVIhaJoXxBAqgPc305sYmEiBEQ8JgSScqRiURoF_8C5OgOk5zmyc7zlnL08kkwZKthxpBRYntN28ui2m7KnJGg7PjX8V7ZpvslkHODx3mts5O5nrhsrU_DuFxO11SnC1hW" target="_blank">IICSPA Welcomes Fourteen New Fellows for 2024 - Yahoo news</a></li>
          <li><a href="https://toolhunt.io/sai-kumar-arava-ai-and-machine-learning-are-transforming-business-insights/" target="_blank">AI and Machine Learning Are Transforming Business Insights - Toolhunt</a></li>
        </ul>
      </div>
    </div>
  )
}

export default Details